<template>
  <v-app-bar class="navbar" app height="50px">
    <div class="header-title">
      <div class="d-flex align-center">
          <v-btn icon small dark class="mr-2 btn-prev" @click.prevent="$router.back()">
              <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <h1>{{ title }}</h1>
      </div>
      <v-btn icon dark class="pt-2" @click.prevent="$router.push('/cart')">
        <v-badge right color="red" overlap :content="`${this.$store.getters.getShoppingCart.length}`">
          <v-icon class="ma-1">mdi-cart</v-icon>
        </v-badge>
      </v-btn>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  name: "HeaderTitlePage",
  data() {
    return {
    }
  },
  props: ["title"],

};
</script>

<style scoped>
/* background: linear-gradient(90deg, rgba(254,203,29,1) 0%, rgba(239,64,53,1) 100%); */

.navbar {
  position: absolute !important;
  width: 95%;
  top: -24px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  background: #2e2253 !important;
  border-radius: 3px !important;
}
.header-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-title h1 {
  color: #fff;
  font-weight: 400;
  font-size: 19px;
}
</style>